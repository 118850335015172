import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CreateGameForm, DeckSwirl } from '../components';

const Home: React.FC = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setOpen(prev => !prev);
    }, 2000);
    return () => clearInterval(interval);
  }, [])

  return (
    <main>
      <CreateGameForm />
      <Wrapper>
        <div>
          <Title>Euchre !</Title>
          <SubTitle>by Tim</SubTitle>
        </div>
        <DeckSwirl open={open} />
      </Wrapper>
    </main>
  )

}
export default Home;

const Title = styled.div`
  font-family: 'Bubblegum Sans', cursive;
  color: ${({ theme }) => theme.color.black};
  font-size: 7rem;
  text-align: center;
`;

const SubTitle = styled.div`
  font-family: 'Inter', sans-serif;
  color: ${({ theme }) => theme.color.black};
  border-top: 1px solid #c3c3c3;
  text-align: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
