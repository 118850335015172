import React from 'react';
import styled from 'styled-components';
import { CARDS } from '../util/constants';
import CardEl from './CardEl';

interface Props {
  open: boolean;
}

const MainPageArt: React.FC<Props> = ({ open }) => {
  return (
    <Wrapper>
      <Center open={open}>
        {CARDS.map((card, i) => (
          <CardWrapper angle={open ? i * 15 : 0} key={i}>
            <CardEl size="lg" value={card} noMargin />
          </CardWrapper>
        ))}
      </Center>
    </Wrapper>
  );
};
export default MainPageArt;

const Wrapper = styled.div`
  margin-top: 2rem;
  width: 100px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Center = styled.div<{ open: boolean }>`
  position: relative;
  width: 1px;
  height: 1px;
  margin-left: ${({ open }) => (open ? 1.5 : 0)}rem;
  transition: all 300ms ease-in-out;
  > div {
    box-shadow: ${({ theme, open }) => (open ? theme.boxShadow : 0)};
  }
`;

const CardWrapper = styled.div<{ angle: number }>`
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -3rem;
  transform: rotate(${({ angle }) => angle}deg);
  transform-origin: 25% 80%;

  transition: transform 300ms ease-in-out;
`;
