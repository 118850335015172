import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { HomePage } from './pages'

const publicRoutes = {
  '/': HomePage,
}

const App: React.FC = () => (
  <Routes>
    {Object.entries(publicRoutes).map(([path, Page]) => (
      <Route path={path} key={path} element={<Page />} />
    ))}
  </Routes>
)

export default App;
