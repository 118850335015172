import React from 'react';
import styled from 'styled-components';
import { AllCards } from '../../types';
import { CARD_IMG_MAP } from '../util/constants';

interface CardProps {
  size?: 'sm' | 'md' | 'lg';
  value: AllCards;
  noMargin?: boolean;
}

const CardEl: React.FC<CardProps> = ({ size = 'md', value, noMargin }) => {
  return <Wrapper size={size} card={value} noMargin={noMargin} />;
};
export default CardEl;

const Wrapper = styled.div<{
  size: 'sm' | 'md' | 'lg';
  card: AllCards;
  noMargin?: boolean;
}>`
  margin: ${({ noMargin }) => (noMargin ? 0 : '5px 5px 50px 5px')};
  cursor: pointer;
  width: ${({size}) => size === 'md' ? 4 : size === 'sm' ? 3 : 6}rem;
  height: ${({size}) => size === 'md' ? 5.5 : size === 'sm' ? 4.125 : 8.25}rem;

  background-color: #fff;
  border-radius: 0.2rem;
  border: 1px solid #c3c3c3;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  background-image: url(/cards/${({ card }) => CARD_IMG_MAP[card]}.svg);
`;
