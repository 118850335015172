import { AllCards, Card } from "../../types";

export const CARDS: Card[] = [
  '9h',
  '10h',
  'jh',
  'qh',
  'kh',
  'ah',
  '9d',
  '9c',
  '10c',
  'jc',
  'qc',
  'kc',
  'ac',
  '10d',
  'jd',
  'qd',
  'kd',
  'ad',
  '9s',
  '10s',
  'js',
  'qs',
  'ks',
  'as',
];


export const CARD_IMG_MAP: Record<AllCards, string> = {
  '9s': 'spades/spades-r09',
  '9d': 'diamonds/diamonds-r09',
  '9h': 'hearts/hearts-r09',
  '9c': 'clubs/clubs-r09',
  '10s': 'spades/spades-r10',
  '10d': 'diamonds/diamonds-r10',
  '10h': 'hearts/hearts-r10',
  '10c': 'clubs/clubs-r10',
  js: 'spades/spades-J',
  jd: 'diamonds/diamonds-J',
  jh: 'hearts/hearts-J',
  jc: 'clubs/clubs-J',
  qs: 'spades/spades-Q',
  qd: 'diamonds/diamonds-Q',
  qh: 'hearts/hearts-Q',
  qc: 'clubs/clubs-Q',
  ks: 'spades/spades-K',
  kd: 'diamonds/diamonds-K',
  kh: 'hearts/hearts-K',
  kc: 'clubs/clubs-K',
  as: 'spades/spades-A',
  ad: 'diamonds/diamonds-A',
  ah: 'hearts/hearts-A',
  ac: 'clubs/clubs-A',
  back: 'backs/blue',
  '5s': 'spades/spades-r05',
  '5d': 'diamonds/diamonds-r05',
  '5h': 'hearts/hearts-r05',
  '5c': 'clubs/clubs-r05',
};
